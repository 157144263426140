import React from "react";
import { Redirect, Route } from "react-router";
import { Routes } from "routes";
import { RouteUtil } from "utils/route-utils";
import MainLayout from "components/App/MainLayout/MainLayout";

const AuthenticatedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  if (!localStorage.getItem("auth_token")) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect to={{ pathname: RouteUtil.getUrl(Routes.login) }} />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

export default AuthenticatedRoute;
