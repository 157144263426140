import React from "react";

const ValidationErrorMessage: React.FC<{ message?: string | boolean }> = ({
  message,
}) =>
  message ? (
    <p className="text-xs py-2 text-red-700">
      {typeof message === "string" ? message : "Error!"}
    </p>
  ) : null;
export default ValidationErrorMessage;
