import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import IconButton from "components/shared/IconButton/IconButton";
import Table from "components/shared/Table/Table";
import React, { useState } from "react";
import { Project } from "models/Project";
import EditProjectModal from "./EditProjectModal";
import ContainerHeader from "components/shared/ContainerHeader/ContainerHeader";
import WithSpinner from "components/shared/Spinner/WithSpinner";
import { useQueryClient } from "react-query";
import Spinner from "components/shared/Spinner/Spinner";
import toast from "react-hot-toast";
import { projectsQueryKeys } from "query/query-key-factory";
import { useProjectDelete, useProjectsList } from "query/projects";

const Projects: React.FC = () => {
  const queryClient = useQueryClient();
  const [showEditProject, setShowEditProject] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<Project>();

  const { data, isLoading: firstTimeLoading } = useProjectsList();

  const { isLoading, mutate, variables: deleteId } = useProjectDelete();

  const projects: Project[] = data ?? [];

  const onClose = () => {
    setProjectToEdit(undefined);
    setShowEditProject(false);
  };

  return (
    <div className="h-full overflow-hidden">
      <ContainerHeader
        title={"Projects"}
        primaryAction={{
          content: "Add New Project",
          onAction: () => {
            setProjectToEdit(undefined);
            setShowEditProject(true);
          },
        }}
      />
      <WithSpinner loading={firstTimeLoading}>
        <Table
          headers={[
            {
              title: "Id",
              columnOptions: { emphasized: true, className: "w-2" },
            },
            { title: "Name", columnOptions: { useSorting: true } },
            {
              title: "Actions",
              columnOptions: {
                alignCenter: true,
                className: "w-48 pr-6 md:pr-8 lg:pr-20",
              },
            },
          ]}
          items={projects.map((project) => [
            project.id,
            project.name,
            <div className="flex items-center justify-center space-x-4">
              <IconButton
                onClick={() => {
                  setProjectToEdit(project);
                  setShowEditProject(true);
                }}
              >
                <PencilAltIcon className="h-6 w-6" />
              </IconButton>
              {isLoading && deleteId === project.id ? (
                <Spinner />
              ) : (
                <IconButton
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure want to delete " + project.name
                      )
                    ) {
                      mutate(project.id, {
                        onSuccess: () => {
                          queryClient.setQueryData(
                            projectsQueryKeys.list,
                            projects.filter((p) => p.id !== project.id)
                          );
                        },
                      });

                      toast.success("Project has been deleted successfully.");
                    }
                  }}
                  type={"danger"}
                >
                  <TrashIcon className="h-6 w-6" />
                </IconButton>
              )}
            </div>,
          ])}
        />
      </WithSpinner>
      {showEditProject && (
        <EditProjectModal
          project={projectToEdit}
          onClose={onClose}
          onSuccess={() => {
            onClose();
          }}
        />
      )}
    </div>
  );
};

export default Projects;
