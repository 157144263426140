import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { EmptyResponse } from "../responses/empty.response";
import { Company } from "../models/Company";

class CompanyService {
  static getInstance(): CompanyService {
    return new CompanyService();
  }

  getAll(): Promise<Company[]> {
    return apiService
      .get<ListResponse<Company>>("/admin/companies", true)
      .then((response) => response.data);
  }

  add(data: { name: string }): Promise<Company> {
    return apiService
      .post<ShowResponse<Company>>("/admin/companies", true, data)
      .then((response) => response.data);
  }

  update(
    id: number,
    data: {
      name: string;
    }
  ): Promise<Company> {
    return apiService
      .put<ShowResponse<Company>>("/admin/companies/" + id, true, data)
      .then((response) => response.data);
  }

  delete(id: number): Promise<boolean> {
    return apiService
      .delete<EmptyResponse>("/admin/companies/" + id, true)
      .then((response) => response.data.success);
  }
}

export const companyService = CompanyService.getInstance();
