import * as axios from "axios";
import { AxiosInstance } from "axios";

class ApiService {
  private axiosInstance: AxiosInstance;
  BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

  constructor() {
    console.log(this.BASE_URL);
    this.axiosInstance = axios.default.create();
  }

  static getInstance(): ApiService {
    return new ApiService();
  }

  get<T>(
    uri: string,
    useAuthHeaders: boolean,
    queryParams?: { [key: string]: any },
    responseType?: any
  ): Promise<T> {
    return this.axiosInstance
      .get(this._fullyQualifiedUri(uri), {
        params: queryParams,
        ...this._getHttpHeaders(useAuthHeaders),
        responseType: responseType || "json",
      })
      .then((response) => response.data)
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.status === 401) {
            localStorage.clear();
            window.location.reload();
          }
          throw err.response.data;
        } else {
          throw err;
        }
      });
  }

  post<T>(uri: string, useAuthHeaders: boolean, data?: any): Promise<T> {
    return this.axiosInstance
      .post(
        this._fullyQualifiedUri(uri),
        data,
        this._getHttpHeaders(useAuthHeaders)
      )
      .then((response) => response.data)
      .catch((err) => {
        if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          throw err;
        }
      });
  }

  put<T>(uri: string, useAuthHeaders: boolean, data: any): Promise<T> {
    return this.axiosInstance
      .put(
        this._fullyQualifiedUri(uri),
        data,
        this._getHttpHeaders(useAuthHeaders)
      )
      .then((response) => response.data)
      .catch((err) => {
        if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          throw err;
        }
      });
  }

  delete<T>(uri: string, useAuthHeaders: boolean): Promise<T> {
    return this.axiosInstance
      .delete(
        this._fullyQualifiedUri(uri),
        this._getHttpHeaders(useAuthHeaders)
      )
      .then((response) => response.data)
      .catch((err) => {
        if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          throw err;
        }
      });
  }

  private _getHttpHeaders(useAuthHeaders: boolean, additionalHeaders?: any) {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    };

    if (useAuthHeaders) {
      headers["Authorization"] = `Bearer ${localStorage.getItem("auth_token")}`;
    }

    return { headers };
  }

  private _fullyQualifiedUri(uri: string) {
    return `${this.BASE_URL}${uri}`;
  }
}

export const apiService = ApiService.getInstance();
