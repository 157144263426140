import React, { FunctionComponent } from "react";
import Spinner from "./Spinner";

interface OwnProps {
  loading?: boolean;
}

type Props = OwnProps;

const WithSpinner: FunctionComponent<Props> = ({ children, loading }) => {
  if (!loading) {
    return <>{children}</>;
  }
  return (
    <div className="h-full w-full flex items-center justify-center">
      <Spinner size="large" />
    </div>
  );
};

export default WithSpinner;
