const ErrorMessage: React.FC<{ message?: string }> = ({ message }) =>
  message ? (
    <div
      className="bg-orange-100 border-l-4 border-red-500 text-orange-700 p-2 mb-4"
      role="alert"
    >
      <p className="font-bold text-red-700">Something went wrong!</p>
      <p className="text-red-700">{message}</p>
    </div>
  ) : null;
export default ErrorMessage;
