import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { authService } from "services/AuthService";
import ValidationErrorMessage from "components/shared/ValidationErrorMessage/ValdidationErrorMessage";
import ErrorMessage from "components/shared/ErrorMessage/ErrorMessage";
import toast from "react-hot-toast";
import { userService } from "services/UserService";
import Modal from "components/shared/Modal/Modal";
import Button from "components/shared/Button/Button";

const schema = yup.object().shape({
  oldPassword: yup.string().required("This field is required."),
  newPassword: yup
    .string()
    .required("This field is required.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: yup
    .string()
    .required("This field is required.")
    .when("newPassword", {
      is: (password: string) => !!(password && password.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Password doesn't match"),
    }),
});

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

const ChangePasswordModal: React.FC<Props> = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <h2 className="text-center text-xl">Change Password</h2>
      <Formik
        validationSchema={schema}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          try {
            setStatus("");
            setSubmitting(true);
            await userService.changePassword({
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              newPasswordConfirmation: values.confirmPassword,
            });
            resetForm();
            props.onSuccess();

            toast.success("Your Password has been set successfully.");
          } catch (e: any) {
            setStatus(e.errors[0].message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          status,
        }) => (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md -space-y-px">
              <div>
                <label htmlFor="old-password" className="sr-only">
                  Old Password
                </label>
                <input
                  id="old-password"
                  name="oldPassword"
                  type="password"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Old Password"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <ValidationErrorMessage
                message={touched.oldPassword && errors.oldPassword}
              />
            </div>
            <div className="rounded-md -space-y-px">
              <div>
                <label htmlFor="new-password" className="sr-only">
                  New Password
                </label>
                <input
                  id="new-password"
                  name="newPassword"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="New Password"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <ValidationErrorMessage
                message={touched.newPassword && errors.newPassword}
              />
            </div>
            <div className="rounded-md -space-y-px">
              <div>
                <label htmlFor="confirm-password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <ValidationErrorMessage
                message={touched.confirmPassword && errors.confirmPassword}
              />
            </div>
            <div>
              <ErrorMessage message={status} />
              <Button
                onClick={() => handleSubmit()}
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                className="group relative flex mx-auto justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-70"
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePasswordModal;
