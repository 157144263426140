import { Formik } from "formik";
import React from "react";
import { Company } from "models/Company";
import { preventDefault } from "utils/form-utils";
import ErrorMessage from "components/shared/ErrorMessage/ErrorMessage";
import Modal from "components/shared/Modal/Modal";
import ValidationErrorMessage from "components/shared/ValidationErrorMessage/ValdidationErrorMessage";
import * as yup from "yup";
import { User } from "models/User";
import { userService } from "services/UserService";
import Button from "components/shared/Button/Button";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { usersQueryKeys } from "query/query-key-factory";
import { useCompaniesList } from "query/companies";

interface CreateEditUserModalProps {
  user?: User;
  onClose: () => void;
  onSuccess: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required("This field is required."),
  email: yup.string().email().required("Enter valid email."),
  role: yup.string().required("This field is required."),
  password: yup
    .string()
    .optional()
    .test("len", "Must be at least 6 characters", (val) => {
      return (val || "")?.length === 0 || (val || "")?.length > 5;
    }),
  company: yup.number().required("This field is required."),
});

const CreateEditUserModal: React.FC<CreateEditUserModalProps> = ({
  user,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const { data } = useCompaniesList();

  const companies: Company[] = data ?? [];

  return (
    <Modal onClose={onClose}>
      <h2 className="text-center text-xl">
        {user ? "Edit User" : "Invite User"}
      </h2>
      {companies?.length && (
        <Formik
          validationSchema={schema}
          initialValues={{
            name: user?.name || "",
            email: user?.email || "",
            role: user?.role || "EMPLOYEE",
            password: "",
            company: user?.company?.id || companies?.[0]?.id || "",
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            try {
              setSubmitting(true);
              setStatus("");
              if (user) {
                const details = {
                  id: user.id,
                  role: values.role,
                  email: values.email,
                  name: values.name,
                  password: values.password,
                };
                const res = await userService.update(details);

                if (res) {
                  queryClient.setQueryData<User[] | undefined>(
                    usersQueryKeys.list,
                    (prev: User[] | undefined) => {
                      if (prev) {
                        return prev.map((d) =>
                          d.id === user.id ? { ...d, ...details } : d
                        );
                      } else {
                        return [{ ...user, ...details }];
                      }
                    }
                  );
                  toast.success("User Details have been updated successfully.");
                } else {
                  toast.error("Error in Updating User details.");
                }
              } else {
                if (!values.password) {
                  setStatus("Must enter a password.");
                  return;
                }
                const res = await userService.invite({
                  name: values.name,
                  role: values.role,
                  email: values.email,
                  password: values.password,
                  company_id: values.company,
                });

                if (res) {
                  await queryClient.invalidateQueries(usersQueryKeys.list);
                  toast.success("User Details have been added successfully.");
                } else {
                  toast.error("Error in Adding User details.");
                }
              }
              setSubmitting(false);
              onSuccess();
            } catch (e: any) {
              setStatus(e.errors?.[0]?.message ?? "Something went wrong.");
              toast.error(e.errors?.[0]?.message ?? "Something went wrong.");
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            handleChange,
            values,
            errors,
            status,
            touched,
            handleBlur,
          }) => (
            <form
              className="mt-8 space-y-6"
              onSubmit={preventDefault(handleSubmit)}
            >
              <div className="-space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <ValidationErrorMessage
                  message={touched?.name && errors.name}
                />
              </div>

              <div className="-space-y-px">
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <ValidationErrorMessage
                  message={touched.email && errors.email}
                />
              </div>

              <div className="-space-y-px">
                <div>
                  <label htmlFor="role" className="sr-only">
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    required
                    className="rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    value={values.role || "EMPLOYEE"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="EMPLOYEE" key="1">
                      Employee
                    </option>
                    <option value="ADMIN" key="2">
                      Admin
                    </option>
                  </select>
                </div>
                <ValidationErrorMessage message={touched.role && errors.role} />
              </div>

              {user?.role !== "ADMIN" && (
                <div className="-space-y-px">
                  <div>
                    <label htmlFor="email" className="sr-only">
                      {user?.id ? "New Password" : "Password"}
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="text"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder={user?.id ? "New Password" : "Password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ValidationErrorMessage
                    message={touched.password && errors.password}
                  />
                </div>
              )}

              {/* NOT GIVING OPTION TO EDIT COMPANY FOR NOW */}
              {companies?.length && !user?.id && (
                <div className="-space-y-px">
                  <div>
                    <label htmlFor="role" className="sr-only">
                      Company
                    </label>
                    <select
                      id="company"
                      name="company"
                      required
                      className="rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      value={values.company || companies?.[0]?.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {companies.map((c) => {
                        return (
                          <option value={c.id} key={c.id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <ValidationErrorMessage
                    message={touched.company && errors.company}
                  />
                </div>
              )}

              <ErrorMessage message={status} />
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  onClick={() => handleSubmit()}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
                <Button type={"outlined"} onClick={onClose} className="mr-3">
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default CreateEditUserModal;
