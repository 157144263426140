import { Formik } from "formik";
import React, { MouseEventHandler } from "react";
import { useHistory } from "react-router";
import { Routes } from "routes";
import { authService } from "services/AuthService";
import { preventDefault } from "utils/form-utils";
import { RouteUtil } from "utils/route-utils";
import * as yup from "yup";
import ValidationErrorMessage from "components/shared/ValidationErrorMessage/ValdidationErrorMessage";
import ErrorMessage from "components/shared/ErrorMessage/ErrorMessage";
import Button from "components/shared/Button/Button";
import toast from "react-hot-toast";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("This field is required.")
    .email("Email must be a valid email"),
  password: yup.string().required("This field is required."),
});

const Login: React.FC = () => {
  const history = useHistory();
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to admin account
          </h2>
        </div>
        <Formik
          validationSchema={schema}
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            try {
              setSubmitting(true);
              setStatus("");
              await authService.login(values.email, values.password);
              history.push(RouteUtil.getUrl(Routes.projects));
              toast.success("Logged in successfully.");
            } catch (e: any) {
              setStatus((e.errors && e.errors[0].message) || e.toString());
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            handleChange,
            values,
            errors,
            status,
            touched,
            handleBlur,
            setTouched,
          }) => (
            <form
              className="mt-8 space-y-6"
              onSubmit={preventDefault(handleSubmit)}
            >
              <div className="rounded-md -space-y-px">
                <label htmlFor="email-address" className="sr-only">
                  Email address {status}
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.email && (
                  <ValidationErrorMessage message={errors.email} />
                )}
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={() => setTouched({ ...touched, password: true })}
                />
                {touched.password && (
                  <ValidationErrorMessage message={errors.password} />
                )}
                <ErrorMessage message={status} />
              </div>
              <Button
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                fullWidth
                onClick={handleSubmit as any}
              >
                Sign In
              </Button>
              <input type="submit" hidden />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
