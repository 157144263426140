import { Menu } from "@headlessui/react";
import { ReactElement } from "react";

interface Props {
  title: string | ReactElement;
  options: { title: string; onClickHandler: () => void }[];
  disabled?: boolean;
  itemsContainerStyle?: string;
  inactiveItemStyle?: string;
  activeItemStyle?: string;
  menuButtonStyle?: string;
}

const DropDown: React.FC<Props> = (props) => {
  return (
    <div className="relative">
      <Menu>
        <Menu.Button className={"px-3 py-2 " + props.menuButtonStyle}>
          {props.title}
        </Menu.Button>
        <Menu.Items
          className={"absolute max-w-min " + props.itemsContainerStyle}
        >
          {props.options.map((option, index) => (
            <Menu.Item
              key={index}
              onClick={option.onClickHandler}
              disabled={props.disabled}
            >
              {({ active }) => (
                <div
                  className={`cursor-pointer px-4 py-2 ${
                    active ? props.activeItemStyle : props.inactiveItemStyle
                  }`}
                >
                  {option.title}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

DropDown.defaultProps = {
  title: "Menu",
  options: [],
  disabled: false,
  itemsContainerStyle: "bg-white",
  inactiveItemStyle: "text-xs uppercase font-bold text-black",
  activeItemStyle: "text-xs uppercase font-bold text-yellow-100 bg-gray-500",
  menuButtonStyle: "text-xs uppercase font-bold",
};

export default DropDown;
