import { Formik } from "formik";
import React from "react";
import { Company } from "models/Company";
import { preventDefault } from "utils/form-utils";
import ErrorMessage from "../shared/ErrorMessage/ErrorMessage";
import Modal from "components/shared/Modal/Modal";
import ValidationErrorMessage from "components/shared/ValidationErrorMessage/ValdidationErrorMessage";
import * as yup from "yup";
import { companyService } from "services/CompanyService";
import Button from "components/shared/Button/Button";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { companiesQueryKeys } from "query/query-key-factory";

interface EditCompanyModalProps {
  company?: Company;
  onClose: () => void;
  onSuccess: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required("This field is required."),
});

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
  company,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  return (
    <Modal onClose={onClose}>
      <Formik
        validationSchema={schema}
        initialValues={{ name: company?.name || "" }}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            setSubmitting(true);
            setStatus("");
            if (company) {
              const res = await companyService.update(company.id, values);
              queryClient.setQueryData<Company[] | undefined>(
                companiesQueryKeys.list,
                (prev) => {
                  if (prev) {
                    return prev.map((d) => (d.id === res.id ? res : d));
                  } else {
                    return [res];
                  }
                }
              );

              toast.success("Company has been updated successfully.");
            } else {
              const res = await companyService.add(values);
              queryClient.setQueryData<Company[] | undefined>(
                companiesQueryKeys.list,
                (prev) => {
                  if (prev) {
                    return [...prev, res];
                  } else {
                    return [res];
                  }
                }
              );

              toast.success("Company has been added successfully.");
            }
            setSubmitting(false);
            onSuccess();
          } catch (e: any) {
            setStatus(e.errors[0].message);
            toast.error("Error!");
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          handleChange,
          values,
          errors,
          status,
        }) => (
          <form
            className="mt-8 space-y-6"
            onSubmit={preventDefault(handleSubmit)}
          >
            <div className="-space-y-px">
              <div>
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Company name"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <ValidationErrorMessage message={errors.name} />
            </div>
            <ErrorMessage message={status} />
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <Button
                onClick={() => handleSubmit()}
                disabled={!isValid}
                loading={isSubmitting}
              >
                Submit
              </Button>
              <Button type={"outlined"} onClick={onClose} className="mr-3">
                Cancel
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditCompanyModal;
