import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import classNames from "classnames";
import Spinner from "../Spinner/Spinner";

interface OwnProps {
  type?: "primary" | "success" | "danger" | "outlined";
  loading?: boolean;
  fullWidth?: boolean;
}

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & OwnProps;

const Button: FunctionComponent<Props> = ({
  children,
  type,
  loading,
  fullWidth,
  ...props
}) => {
  return (
    <button
      {...props}
      type="button"
      disabled={loading || props.disabled}
      className={classNames(
        "relative flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2",
        {
          "w-full": fullWidth,
          "text-white": type !== "outlined",
          "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500":
            type === "primary",
          "bg-red-600 hover:bg-red-700 focus:ring-red-500": type === "danger",
          "bg-green-600 hover:bg-green-700 focus:ring-green-500":
            type === "success",
          "bg-white focus:ring-indigo-600 border border-gray-300 shadow-sm text-gray-700 hover:text-gray-600":
            type === "outlined",
          "opacity-50 cursor-not-allowed": props.disabled || loading,
          [props.className as string]: props.className,
        }
      )}
    >
      {loading ? (
        <span className="flex items-center">
          <Spinner size="small" color="secondary" />
          <span className="ml-2">{children}</span>
        </span>
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = {
  type: "primary",
};
export default Button;
