import { apiService } from "./ApiService";
import { EmptyResponse } from "../responses/empty.response";
import {
  ChangePasswordParams,
  User,
  UserObject,
  UsersListAPIParams,
} from "../models/User";

class UserService {
  static getInstance(): UserService {
    return new UserService();
  }

  // getAll(page: number ): Promise<UserObject> {
  //   return apiService
  //     .get<UserObject>(`/admin/users/?page=${page}` , true)
  //     .then((response) => response);
  // }

  archive(user_id: number): Promise<boolean> {
    return apiService
      .post<EmptyResponse>(`/admin/archive/user/${user_id}`, true)
      .then((response) => response.data.success);
  }

  getAll(params: UsersListAPIParams): Promise<UserObject> {
    return apiService
      .get<UserObject>("/admin/users", true, params)
      .then((response) => response);
  }

  invite(data: {
    name: string;
    email: string;
    company_id: number | string;
    role: string;
    password: string;
  }): Promise<boolean> {
    return apiService
      .post<EmptyResponse>("/admin/users", true, data)
      .then((response) => response.data.success);
  }

  update(data: {
    name: string;
    email: string;
    role: string;
    id: number;
    password?: string;
  }): Promise<boolean> {
    if (!data?.password?.length) {
      delete data.password;
    }
    return apiService
      .put<EmptyResponse>("/admin/users", true, data)
      .then((response) => response.data.success);
  }

  attachProject(data: {
    user_id: number;
    project_id: number;
  }): Promise<boolean> {
    return apiService
      .post<EmptyResponse>(
        "/admin/users/" + data.user_id + "/projects/" + data.project_id,
        true,
        data
      )
      .then((response) => response.data.success);
  }

  detachProject(data: {
    user_id: number;
    project_id: number;
  }): Promise<boolean> {
    return apiService
      .delete<EmptyResponse>(
        "/admin/users/" + data.user_id + "/projects/" + data.project_id,
        true
      )
      .then((response) => response.data.success);
  }

  getLoggedInUser(): Promise<User> {
    return apiService
      .get<{ data: User }>("/me", true)
      .then((response) => response.data);
  }

  changePassword(data: ChangePasswordParams): Promise<boolean> {
    return apiService
      .post<EmptyResponse>("/change-password", true, data)
      .then((response) => response.data.success);
  }
}

export const userService = UserService.getInstance();
