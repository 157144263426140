import { useMutation, useQuery } from "react-query";
import { projectsQueryKeys } from "./query-key-factory";
import { projectService } from "services/ProjectService";

export const useProjectsList = () => {
  return useQuery(projectsQueryKeys.list, projectService.getAll);
};

export const useProjectDelete = () => {
  return useMutation<boolean, unknown, number>(projectService.delete);
};

export const useProjectByCompany = (company_id: number) => {
  return useQuery(projectsQueryKeys.getByCompanyId(company_id), () =>
    projectService.getByCompanyId(company_id)
  );
};
