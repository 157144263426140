import React, { useState } from "react";
import { Project } from "models/Project";
import { User, UserObject } from "models/User";
import EditProjectUserModal from "../Projects/EditProjectUserModal";
import CreateEditUserModal from "./CreateEditUserModal";
import Table from "components/shared/Table/Table";
import IconButton from "components/shared/IconButton/IconButton";
import { ArchiveIcon, PencilAltIcon } from "@heroicons/react/outline";
import ContainerHeader from "components/shared/ContainerHeader/ContainerHeader";
import WithSpinner from "components/shared/Spinner/WithSpinner";
import { useArchiveUser, useUsersList } from "query/users";
import ConfirmationModal from "components/shared/Modal/ConfirmationModal";
import { useQueryClient } from "react-query";
import { usersQueryKeys } from "query/query-key-factory";
import toast from "react-hot-toast";
import Spinner from "components/shared/Spinner/Spinner";

const Users: React.FC = () => {
  const queryClient = useQueryClient();
  const [showArchiveUser, setShowArchiveUser] = useState<boolean>(false);
  const [showCreateUser, setShowCreateUser] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [showEditUserProject, setShowEditProjectUser] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const userListQueryParams = {
    page,
    search: searchQuery,
  };
  const { isLoading: firstTimeLoading, data } =
    useUsersList(userListQueryParams);
  const {
    mutate,
    isLoading: isArchiving,
    variables: archivingUserId,
  } = useArchiveUser();

  const users: User[] = data?.data ?? [];

  return (
    <div className="h-full overflow-hidden">
      <ContainerHeader
        title={"Users"}
        primaryAction={{
          content: "Invite User",
          onAction: () => setShowCreateUser(true),
        }}
        allowSearch
        onSearchSubmit={(query) => {
          setSearchQuery(query);
          setPage(1);
        }}
      />

      <WithSpinner loading={firstTimeLoading}>
        <Table
          headers={[
            { title: "Id", columnOptions: { emphasized: true } },
            { title: "Name", columnOptions: { useSorting: true } },
            "Email",
            { title: "Company", columnOptions: { useSorting: true } },
            "Role",
            "Projects",
            { title: "Edit User", columnOptions: { alignCenter: true } },
            {
              title: "Edit their Project",
              columnOptions: { alignCenter: true },
            },
            {
              title: "Archive",
              columnOptions: { alignCenter: true },
            },
          ]}
          items={users.map((user) => [
            user.id,
            user.name,
            user.email,
            user?.company?.name ?? "No Company",
            user?.role ?? "Not found",
            user?.projects?.length
              ? user.projects.map((k: Project) => k.name).join(", ")
              : "No Projects",
            <IconButton
              onClick={() => {
                setSelectedUser(user);
                setShowEditUser(true);
              }}
            >
              <PencilAltIcon className="h-6 w-6" />
            </IconButton>,
            <IconButton
              onClick={() => {
                setSelectedUser(user);
                setShowEditProjectUser(true);
              }}
            >
              <PencilAltIcon className="h-6 w-6" />
            </IconButton>,
            isArchiving && user.id === archivingUserId ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : (
              <IconButton
                onClick={() => {
                  setSelectedUser(user);
                  setShowArchiveUser(true);
                }}
              >
                <ArchiveIcon className="w-6 h-6 " />
              </IconButton>
            ),
          ])}
          onPageChange={(page) => setPage(page)}
          pagination={
            data?.metaData?.pagination
              ? {
                  currentPage: data?.metaData.pagination.current_page,
                  totalPages: data.metaData.pagination.last_page,
                }
              : undefined
          }
        />
      </WithSpinner>
      {showArchiveUser && (
        <ConfirmationModal
          buttonDisabled={isArchiving}
          onConfirmation={async () => {
            mutate(selectedUser!.id, {
              onError: (err) => {
                console.log("error: ", err);
                toast.error(
                  "Some error occurred while archiving user.\n" + err
                );
              },
              onSuccess: () => {
                const unarchivedUsers = users.filter(
                  (u) => u.id !== selectedUser!.id
                );
                queryClient.setQueryData<UserObject | undefined>(
                  usersQueryKeys.listWithParams(userListQueryParams),
                  (oldData: UserObject | undefined) =>
                    oldData && { ...oldData, data: unarchivedUsers }
                );
                setShowArchiveUser(false);
                toast.success("User has been archived successfully.");
              },
            });
          }}
          onClose={() => {
            setShowArchiveUser(false);
          }}
          title="Archive User"
          description={
            <>
              Do you want to archive{" "}
              <u>
                <i>{selectedUser?.name + "'s" || ""}</i>
              </u>{" "}
              account?
            </>
          }
          confirmButtonType="danger"
        />
      )}
      {showCreateUser && (
        <CreateEditUserModal
          onClose={() => {
            setShowCreateUser(false);
          }}
          onSuccess={() => {
            setShowCreateUser(false);
          }}
        />
      )}

      {showEditUser && (
        <CreateEditUserModal
          user={selectedUser}
          onClose={() => {
            setShowEditUser(false);
          }}
          onSuccess={() => {
            setShowEditUser(false);
          }}
        />
      )}
      {showEditUserProject && (
        <EditProjectUserModal
          user={selectedUser}
          onClose={() => {
            setShowEditProjectUser(false);
          }}
          onSuccess={() => {
            setTimeout(() => {
              setShowEditProjectUser(false);
            }, 500);
          }}
        />
      )}
    </div>
  );
};

export default Users;
