import React, { FunctionComponent, useState } from "react";
import Button from "../Button/Button";
import { v4 as uuidv4 } from "uuid";
import { SearchIcon } from "@heroicons/react/outline";
import IconButton from "../IconButton/IconButton";

interface Action {
  content: string;
  onAction?: () => void;
  disabled?: boolean;
  loading?: boolean;
  destructive?: boolean;
}

interface OwnProps {
  title: string;
  primaryAction?: Omit<Action, "destructive">;
  secondaryActions?: Action[];
  allowSearch?: boolean;
  onSearchSubmit?: (query: string) => void;
}

type Props = OwnProps;

const ContainerHeader: FunctionComponent<Props> = ({
  title,
  primaryAction,
  secondaryActions,
  allowSearch,
  onSearchSubmit,
}) => {
  const [searchText, setSearchText] = useState("");
  return (
    <div className="sticky top-0 bg-white px-4 py-3 border-b border-gray-200 sm:px-6">
      <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="flex items-center space-x-3">
          <h3 className="ml-4 text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          {allowSearch && (
            <div className="flex space-x-3 items-center">
              <input
                type="search"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Search..."
                onKeyDownCapture={(e) => {
                  if (e.key === "Enter") {
                    onSearchSubmit?.(searchText);
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchText(value);
                  if (value === "") {
                    onSearchSubmit?.(value);
                  }
                }}
                value={searchText}
              />
              <IconButton onClick={() => onSearchSubmit?.(searchText)}>
                <SearchIcon className="h-6 w-6" />
              </IconButton>
            </div>
          )}
        </div>
        <div className="ml-4 flex-shrink-0 space-x-2">
          {secondaryActions &&
            secondaryActions?.map((action) => (
              <Button
                onClick={action?.onAction}
                key={uuidv4()}
                type={action?.destructive ? "danger" : "outlined"}
                loading={action?.loading}
                disabled={action?.disabled}
              >
                {action.content}
              </Button>
            ))}
          {primaryAction && (
            <Button
              loading={primaryAction?.loading}
              disabled={primaryAction?.disabled}
              onClick={primaryAction?.onAction}
            >
              {primaryAction.content}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContainerHeader;
