import { Formik } from "formik";
import React from "react";
import { preventDefault } from "utils/form-utils";
import ErrorMessage from "components/shared/ErrorMessage/ErrorMessage";
import Modal from "components/shared/Modal/Modal";
import ValidationErrorMessage from "components/shared/ValidationErrorMessage/ValdidationErrorMessage";
import * as yup from "yup";
import { Project } from "models/Project";
import { projectService } from "services/ProjectService";
import { Company } from "models/Company";
import WithSpinner from "components/shared/Spinner/WithSpinner";
import Button from "components/shared/Button/Button";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { projectsQueryKeys } from "query/query-key-factory";
import { useCompaniesList } from "query/companies";

interface EditProjectModalProps {
  project?: Project;
  onClose: () => void;
  onSuccess: () => void;
  loading?: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required("This field is required."),
  company_id: yup.number().required("This field is required."),
});

const EditProjectModal: React.FC<EditProjectModalProps> = ({
  project,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useCompaniesList();

  const companies: Company[] = data ?? [];

  return (
    <Modal onClose={onClose}>
      <WithSpinner loading={isLoading}>
        <Formik
          validationSchema={schema}
          initialValues={{
            name: project?.name ?? "",
            company_id: project?.company?.id ?? companies?.[0]?.id ?? "",
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            try {
              setSubmitting(true);
              setStatus("");
              if (project) {
                const res = await projectService.update(project.id, values);
                queryClient.setQueryData<Project[] | undefined>(
                  projectsQueryKeys.list,
                  (prev) => {
                    if (prev) {
                      return prev.map((d) => (d.id === res.id ? res : d));
                    } else {
                      return [res];
                    }
                  }
                );

                toast.success("Project has been updated successfully.");
              } else {
                const res = await projectService.add({
                  ...values,
                  company_id: values.company_id || 0,
                });
                queryClient.setQueryData<Project[] | undefined>(
                  projectsQueryKeys.list,
                  (prev) => {
                    if (prev) {
                      return [...prev, res];
                    } else {
                      return [res];
                    }
                  }
                );

                toast.success("Project has been added successfully.");
              }
              setSubmitting(false);
              onSuccess();
            } catch (e: any) {
              setSubmitting(false);
              setStatus(e.errors[0].message);
            }
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            handleChange,
            values,
            errors,
            status,
          }) => (
            <form
              className="mt-8 space-y-6"
              onSubmit={preventDefault(handleSubmit)}
            >
              <div className="-space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Project name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <ValidationErrorMessage message={errors.name} />
                <div>
                  <label
                    htmlFor="company"
                    className="sr-only block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <select
                    id="company"
                    name="company_id"
                    value={values.company_id}
                    onChange={handleChange}
                    disabled={!!project}
                    className="mt-4 block border border-gray-300  w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                </div>
                <ValidationErrorMessage message={errors.company_id} />
              </div>
              <ErrorMessage message={status} />
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
                <Button type={"outlined"} onClick={onClose} className="mr-3">
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </WithSpinner>
    </Modal>
  );
};

export default EditProjectModal;
