import Button from "../Button/Button";
import Modal from "./Modal";

type buttonType = "success" | "danger" | "outlined";

interface Props {
  onConfirmation: () => void;
  onClose: () => void;
  title: string;
  description?: string | React.ReactElement;
  confirmButtonText?: string;
  rejectButtonText?: string;
  confirmButtonType?: buttonType;
  rejectButtonType?: buttonType;
  buttonDisabled?: boolean;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <h2 className="text-center text-2xl mb-4 py-4">{props.title}</h2>
      <p className="text-center mb-6">{props.description}</p>
      <div className="flex">
        <Button
          disabled={props.buttonDisabled}
          type={props.confirmButtonType}
          className="flex-1"
          onClick={props.onConfirmation}
        >
          {props.confirmButtonText}
        </Button>
        <Button
          disabled={props.buttonDisabled}
          type={props.rejectButtonType}
          className="flex-1 ml-8"
          onClick={props.onClose}
        >
          {props.rejectButtonText}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  confirmButtonText: "Yes",
  rejectButtonText: "No",
  buttonDisabled: false,
};

export default ConfirmationModal;
