import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { EmptyResponse } from "../responses/empty.response";
import { Project } from "../models/Project";

class ProjectService {
  static getInstance(): ProjectService {
    return new ProjectService();
  }

  getAll(): Promise<Project[]> {
    return apiService
      .get<ListResponse<Project>>("/admin/projects", true)
      .then((response) => response.data);
  }

  getByCompanyId(companyId: number): Promise<Project[]> {
    return apiService
      .get<ListResponse<Project>>(
        `/admin/projects/${companyId}/company-projects`,
        true
      )
      .then((response) => response.data);
  }

  add(data: { name: string; company_id: number }): Promise<Project> {
    return apiService
      .post<ShowResponse<Project>>("/admin/projects", true, data)
      .then((response) => response.data);
  }

  update(
    id: number,
    data: {
      name: string;
    }
  ): Promise<Project> {
    return apiService
      .put<ShowResponse<Project>>("/admin/projects/" + id, true, data)
      .then((response) => response.data);
  }

  delete(id: number): Promise<boolean> {
    return apiService
      .delete<EmptyResponse>("/admin/projects/" + id, true)
      .then((response) => response.data.success);
  }
}

export const projectService = ProjectService.getInstance();
