import { useMutation, useQuery } from "react-query";
import { userService } from "services/UserService";
import { usersQueryKeys } from "./query-key-factory";
import { ChangePasswordParams, UsersListAPIParams } from "../models/User";

export const useUsersList = (params: UsersListAPIParams) => {
  return useQuery(
    usersQueryKeys.listWithParams(params),
    () => userService.getAll(params),
    { keepPreviousData: true }
  );
};

export const useLoggedInUser = () => {
  return useQuery(usersQueryKeys.me, userService.getLoggedInUser);
};

export const useArchiveUser = () => {
  return useMutation<boolean, unknown, number>(userService.archive);
};
