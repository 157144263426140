import { UsersListAPIParams } from "../models/User";

export const usersQueryKeys = {
  me: ["loggedInUser"],
  list: ["users", "list"],
  listWithParams: (params: UsersListAPIParams) => [
    "users",
    "list",
    ...Object.values(params),
  ],
};

export const projectsQueryKeys = {
  list: ["projects", "list"],
  getByCompanyId: (id: number) => ["projects", "details", id],
};

export const companiesQueryKeys = {
  list: ["companies", "list"],
};
