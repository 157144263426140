import { LoginResponse } from "../responses/login.response";
import { apiService } from "./ApiService";

class AuthService {
  static getInstance(): AuthService {
    return new AuthService();
  }

  login(email: string, password: string): Promise<boolean> {
    return apiService
      .post<LoginResponse>("/login", false, { email, password })
      .then((response) => {
        localStorage.setItem("auth_token", response.token);
        return true;
      });
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  setPassword(token: string, password: string): Promise<any> {
    return apiService.post<any>("/set-password", false, { token, password });
  }
}

export const authService = AuthService.getInstance();
