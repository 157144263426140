import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Project } from "models/Project";
import { User } from "models/User";
import { userService } from "services/UserService";
import Modal from "components/shared/Modal/Modal";
import { useProjectByCompany } from "query/projects";
import Button from "components/shared/Button/Button";
import { useQueryClient } from "react-query";
import { usersQueryKeys } from "../../query/query-key-factory";

interface EditProjectUserModalProps {
  user: User | any;
  onClose: () => void;
  onSuccess: () => void;
}

const EditProjectUserModal: React.FC<EditProjectUserModalProps> = ({
  user,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const { data } = useProjectByCompany(user.company?.id || 0);

  useEffect(() => {
    setSelectedProjectIds(user?.projects?.map((k: Project) => k.id));
  }, []);

  const projects: Project[] = data ?? [];

  return (
    <Modal onClose={onClose}>
      <h4>
        Name : {user.name} <br /> Email : ({user.email})
      </h4>
      {projects.length &&
        projects.map((project) => {
          return (
            <div key={project.id}>
              <label className="divide-x-2">
                <input
                  type="checkbox"
                  className="mr-1"
                  name={project.id.toString()}
                  checked={selectedProjectIds.includes(project.id)}
                  onChange={(e) => {
                    if (selectedProjectIds.includes(project.id)) {
                      setSelectedProjectIds(
                        selectedProjectIds.filter((k) => k !== project.id)
                      );
                    } else {
                      setSelectedProjectIds([
                        ...selectedProjectIds,
                        project.id,
                      ]);
                    }
                  }}
                />
                {project.name}
              </label>
              <br />
            </div>
          );
        })}
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true);
            const originalProjectIds: number[] = user.projects.map(
              (p: Project) => p.id
            );
            const deletedProjectIds: number[] = originalProjectIds.filter(
              (x: number) => !selectedProjectIds.includes(x)
            );
            const addedProjectIds: number[] = selectedProjectIds.filter(
              (x: number) => !originalProjectIds.includes(x)
            );

            await Promise.all(
              deletedProjectIds.map((id) =>
                userService.detachProject({
                  user_id: user.id,
                  project_id: id,
                })
              )
            );

            await Promise.all(
              addedProjectIds.map((id) =>
                userService.attachProject({
                  user_id: user.id,
                  project_id: id,
                })
              )
            );
            await queryClient.invalidateQueries(usersQueryKeys.list);
            setLoading(false);
            onSuccess();
            toast.success("User Projects have been updated successfully.");
          }}
        >
          Submit
        </Button>
        <Button type={"outlined"} onClick={onClose} className="mr-3">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default EditProjectUserModal;
