import { RoutePath } from "./models/RoutePath";

export class Routes {
  static setPassword: RoutePath = {
    path: "set-password",
    parent: null,
  };

  static login: RoutePath = {
    path: "login",
    parent: null,
  };

  static projects: RoutePath = {
    path: "projects",
    parent: null,
  };

  static companies: RoutePath = {
    path: "companies",
    parent: null,
  };

  static users: RoutePath = {
    path: "users",
    parent: null,
  };
}
