import React, { FunctionComponent } from "react";

interface OwnProps {
  height: number | string;
}

type Props = OwnProps;

const ScrollableContainer: FunctionComponent<Props> = ({
  children,
  height,
}) => {
  return (
    <div className="overflow-auto" style={{ height: height }}>
      {children}
    </div>
  );
};

export default ScrollableContainer;
