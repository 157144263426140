import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import classNames from "classnames";

type IconColor =
  | "transparent"
  // | "current"
  | "black"
  | "white"
  | "gray"
  | "red"
  | "green"
  | "yellow"
  | "blue"
  | "indigo"
  | "purple"
  | "pink";

interface OwnProps {
  rounded?: boolean;
  color?: IconColor;
  type?: "primary" | "success" | "danger" | "warning" | "base" | "plain";
}

type Props = OwnProps & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type">;

const colorTypeMap: { [key: string]: IconColor } = {
  primary: "indigo",
  success: "green",
  danger: "red",
  warning: "yellow",
  base: "gray",
  plain: "white",
};

const getColorRelatedClassName = (color: IconColor) => {
  const withoutOffset = ["transparent", "current", "black", "white"];
  if (withoutOffset.includes(color)) {
    return `bg-${color} hover:bg-${color} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}`;
  }
  return `bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500`;
};

const IconButton: FunctionComponent<Props> = ({
  children,
  color,
  type,
  rounded,
  ...props
}) => {
  return (
    <button
      {...props}
      type="button"
      className={classNames(
        "inline-flex items-center p-1 border border-transparent shadow-sm",
        getColorRelatedClassName(color ? color : colorTypeMap[type!]),
        {
          "rounded-full": rounded,
          "text-white": type !== "plain",
          "text-black": type === "plain",
          [props.className as string]: props.className,
        }
      )}
    >
      {children}
    </button>
  );
};

IconButton.defaultProps = {
  rounded: true,
  type: "primary",
};
export default IconButton;
