import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "../Login/Login";
import { RouteUtil } from "../../utils/route-utils";
import { Routes } from "../../routes";
import PublicRoute from "../../guards/PublicRoute";
import SetPassword from "../SetPassword/SetPassword";
import AuthenticatedRoute from "../../guards/AuthenticatedRoute";
import Companies from "../Companies/Companies";
import Projects from "../Projects/Projects";
import Users from "../Users/Users";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <AuthenticatedRoute
            path={RouteUtil.getUrl(Routes.companies)}
            component={Companies}
          />
          <AuthenticatedRoute
            path={RouteUtil.getUrl(Routes.projects)}
            component={Projects}
          />
          <AuthenticatedRoute
            path={RouteUtil.getUrl(Routes.users)}
            component={Users}
          />
          <PublicRoute
            path={RouteUtil.getUrl(Routes.setPassword)}
            component={SetPassword}
          />
          <PublicRoute
            path={RouteUtil.getUrl(Routes.login)}
            component={Login}
          />
          <Route
            render={(props) => (
              <Redirect to={{ pathname: RouteUtil.getUrl(Routes.login) }} />
            )}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
