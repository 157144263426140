import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../routes";
import { authService } from "../../../services/AuthService";
import { RouteUtil } from "../../../utils/route-utils";
import { useQueryClient } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import DropDown from "components/shared/DropDown/DropDown";
import CreateEditUserModal from "components/Users/CreateEditUserModal";
import { useLoggedInUser } from "query/users";
import { usersQueryKeys } from "query/query-key-factory";
import ChangePasswordModal from "components/SetPassword/ChangePasswordModal";

const MainLayout: React.FunctionComponent = (props) => {
  const qc = useQueryClient();
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const {
    data: loggedInUser,
    status: loggedInUserFetchStatus,
    error: loggedInUserFetchError,
  } = useLoggedInUser();
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <nav className="sticky top-0 z-10 px-1 py-3 bg-gray-500 w-full flex items-center">
        <div className="container px-2 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
            <NavLink
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              to={RouteUtil.getUrl(Routes.projects)}
            >
              CARBON
            </NavLink>
          </div>
          <div
            className="lg:flex flex-grow items-center"
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row lg:flex-1 list-none mr-auto">
              <li className="nav-item">
                <NavLink
                  activeClassName="text-yellow-100"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to={RouteUtil.getUrl(Routes.companies)}
                >
                  <span className="ml-2">Companies</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="text-yellow-100"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to={RouteUtil.getUrl(Routes.projects)}
                >
                  <span className="ml-2">Projects</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="text-yellow-100"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  to={RouteUtil.getUrl(Routes.users)}
                >
                  <span className="ml-2">Users</span>
                </NavLink>
              </li>
              <li className="nav-item lg:ml-auto">
                <DropDown
                  disabled={loggedInUserFetchStatus === "loading"}
                  title={
                    <span className="text-white hover:opacity-75 ml-2">
                      Settings
                    </span>
                  }
                  options={[
                    {
                      title: "Edit Profile",
                      onClickHandler: () => {
                        setShowEditUser(true);
                      },
                    },
                    {
                      title: "Change Password",
                      onClickHandler: () => {
                        setShowChangePassword(true);
                      },
                    },
                    {
                      title: "Logout",
                      onClickHandler: () => {
                        authService.logout();
                        qc.clear();
                      },
                    },
                  ]}
                  itemsContainerStyle="lg:right-0 shadow-2xl py-4 bg-white"
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="relative h-full bg-white overflow-auto">
        {props.children}
      </div>
      <Toaster
        position={"bottom-left"}
        toastOptions={{ className: "text-sm" }}
      />
      {showEditUser && loggedInUserFetchStatus === "success" && (
        <CreateEditUserModal
          user={loggedInUser}
          onClose={() => {
            setShowEditUser(false);
          }}
          onSuccess={() => {
            setShowEditUser(false);
            qc.invalidateQueries(usersQueryKeys.me);
          }}
        />
      )}
      {showChangePassword && (
        <ChangePasswordModal
          onClose={() => {
            setShowChangePassword(false);
          }}
          onSuccess={() => {
            setShowChangePassword(false);
          }}
        />
      )}
    </div>
  );
};

export default MainLayout;
