import { useEffect, useState } from "react";

interface WindowInnerSize {
  width: number;
  height: number;
}

function useWindowSize(): WindowInnerSize {
  const [size, setSize] = useState<WindowInnerSize>({ width: 0, height: 0 });
  useEffect(() => {
    function updateSize(): void {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default useWindowSize;
