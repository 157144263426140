import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import IconButton from "components/shared/IconButton/IconButton";
import Table from "components/shared/Table/Table";
import React, { useState } from "react";
import { Company } from "models/Company";
import { companyService } from "services/CompanyService";
import EditCompanyModal from "./EditCompanyModal";
import ContainerHeader from "components/shared/ContainerHeader/ContainerHeader";
import WithSpinner from "components/shared/Spinner/WithSpinner";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import Spinner from "components/shared/Spinner/Spinner";
import { useCompaniesList } from "query/companies";
import { companiesQueryKeys } from "query/query-key-factory";

const Companies: React.FC = () => {
  const queryClient = useQueryClient();

  const [showEditCompany, setShowEditCompany] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState<Company>();

  const { data, isLoading: fetchingCompanies } = useCompaniesList();
  const {
    isLoading,
    mutate,
    variables: deletingId,
  } = useMutation<boolean, unknown, number>(companyService.delete);

  const companies: Company[] = data ?? [];

  const onClose = () => {
    setCompanyToEdit(undefined);
    setShowEditCompany(false);
  };

  return (
    <div className="h-full overflow-hidden">
      <ContainerHeader
        title={"Companies"}
        primaryAction={{
          content: "Add New Company",
          onAction: () => {
            setCompanyToEdit(undefined);
            setShowEditCompany(true);
          },
        }}
      />
      <WithSpinner loading={fetchingCompanies}>
        <Table
          headers={[
            {
              title: "Id",
              columnOptions: { emphasized: true, className: "w-2" },
            },
            { title: "Name", columnOptions: { useSorting: true } },
            {
              title: "Actions",
              columnOptions: {
                alignCenter: true,
                className: "w-48 pr-6 md:pr-8 lg:pr-20",
              },
            },
          ]}
          items={companies.map((company) => [
            company.id,
            company.name,
            <div className="space-x-4">
              <IconButton
                onClick={() => {
                  setCompanyToEdit(company);
                  setShowEditCompany(true);
                }}
              >
                <PencilAltIcon className="h-6 w-6" />
              </IconButton>
              {isLoading && company.id === deletingId ? (
                <Spinner />
              ) : (
                <IconButton
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure want to delete " + company.name
                      )
                    ) {
                      mutate(company.id, {
                        onSuccess: () => {
                          queryClient.setQueryData(
                            companiesQueryKeys.list,
                            companies.filter((c) => c.id !== company.id)
                          );
                          toast.success(
                            "Company has been deleted successfully."
                          );
                        },
                      });
                    }
                  }}
                  type="danger"
                >
                  <TrashIcon className="h-6 w-6" />
                </IconButton>
              )}
            </div>,
          ])}
        />
      </WithSpinner>
      {showEditCompany && (
        <EditCompanyModal
          company={companyToEdit}
          onClose={onClose}
          onSuccess={() => {
            onClose();
          }}
        />
      )}
    </div>
  );
};

export default Companies;
