export const preventDefault = (fn: Function) => {
  return (e: any) => {
    e?.preventDefault?.();
    fn?.(e);
  };
};

export const stopPropagation = (fn: Function) => {
  return (e: any) => {
    e?.stopPropagation?.();
    fn?.(e);
  };
};
