import { Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { authService } from "services/AuthService";
import { useLocation } from "react-router";
import ValidationErrorMessage from "components/shared/ValidationErrorMessage/ValdidationErrorMessage";
import ErrorMessage from "components/shared/ErrorMessage/ErrorMessage";
import toast from "react-hot-toast";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("This field is required.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  confirmPassword: yup
    .string()
    .required("This field is required.")
    .when("password", {
      is: (password: string) => !!(password && password.length > 0),
      then: yup.string().oneOf([yup.ref("password")], "Password doesn't match"),
    }),
});

const SetPassword: React.FC = () => {
  const location = useLocation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const params = new URLSearchParams(location.search);
  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Set your password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600" />
          </div>
          <Formik
            validationSchema={schema}
            initialValues={{ password: "", confirmPassword: "" }}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, resetForm }
            ) => {
              try {
                setStatus("");
                setSubmitting(true);
                await authService.setPassword(
                  params.get("token") || "",
                  values.password
                );
                setShowSuccessMessage(true);
                resetForm();

                toast.success("Your Password has been set successfully.");
              } catch (e: any) {
                setStatus(e.errors[0].message);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              isValid,
              handleChange,
              values,
              errors,
              status,
            }) => (
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <div className="rounded-md -space-y-px">
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                    />
                  </div>
                  <ValidationErrorMessage message={errors.password} />
                  <div>
                    <label htmlFor="confirm-password" className="sr-only">
                      Confirm Password
                    </label>
                    <input
                      id="confirm-password"
                      name="confirmPassword"
                      type="password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                    />
                  </div>
                  <ValidationErrorMessage message={errors.confirmPassword} />
                </div>
                <div>
                  <ErrorMessage message={status} />
                  {showSuccessMessage && (
                    <p className="py-2">
                      Your password has been updated successfully.
                    </p>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-70"
                  >
                    Set Password
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
