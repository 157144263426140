import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ScrollableContainer from "components/shared/ScrollableContainer/ScrollableContainer";
import useWindowSize from "hooks/useWIndowSize";
import classNames from "classnames";
import { useSortBy, useTable, usePagination } from "react-table";
import { Dictionary } from "types";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/react/solid";
import Button from "../Button/Button";

type TableItem = React.ReactNode;

interface ColumnItemOptions {
  emphasized?: boolean;
  alignCenter?: boolean;
  className?: string;
  useSorting?: boolean;
}

type TableHeaderOption =
  | string
  | { title: string; columnOptions?: ColumnItemOptions };

interface OwnProps {
  headers: TableHeaderOption[];
  items: TableItem[][];
  tableHeight?: number | string;
  onPageChange?: (page: number) => void;
  pagination?: { currentPage: number; totalPages: number };
  notFoundText?: string;
}

type Props = OwnProps;

const getNormalizedHeaders = (headers: TableHeaderOption[]) => {
  return headers.map((h) => (typeof h === "string" ? h : h.title));
};

const getData = (items: TableItem[][]) => {
  const data: Dictionary<TableItem>[] = [];
  items.forEach((d) => {
    const x = d.reduce(
      (a: {}, v: TableItem, i) => ({ ...a, [`col${i}`]: v }),
      {}
    );
    data.push(x);
  });
  return data;
};

const Table: FunctionComponent<Props> = ({
  headers,
  items,
  tableHeight,
  onPageChange,
  pagination,
  notFoundText,
}) => {
  const navRef = useRef<HTMLElement>(null);
  const normalizedHeaders = getNormalizedHeaders(headers);
  const data = useMemo(() => getData(items), [items]);
  const columns = useMemo(
    () => normalizedHeaders.map((h, i) => ({ Header: h, accessor: `col${i}` })),
    [headers]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    state,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      manualPagination: true,
      pageCount: pagination ? pagination.totalPages : data.length,
      initialState: { pageIndex: pagination ? pagination.currentPage - 1 : 0 },
    },
    useSortBy,
    usePagination
  );
  const { width } = useWindowSize();
  const ref = useRef<HTMLTableRowElement>(null);
  const [height, setHeight] = useState(600);

  useEffect(() => {
    const ele = ref?.current;
    setHeight((ele?.offsetHeight ?? 0) - (ele?.offsetTop ?? 0));
  }, [ref, width]);

  const getColumnItemOptions = (
    itemIndex: number
  ): ColumnItemOptions | null => {
    const header: TableHeaderOption = headers[itemIndex];

    if (typeof header === "string") {
      return null;
    } else {
      return header.columnOptions ? header.columnOptions : null;
    }
  };

  return (
    <div className="h-full" ref={ref}>
      <ScrollableContainer
        height={+(tableHeight ?? height) - +(navRef.current?.offsetHeight ?? 0)}
      >
        <div className="align-middle inline-block w-full">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="divide-y w-full" {...getTableProps()}>
              <thead className="sticky top-0">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="bg-gray-200"
                    >
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column, hi) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps()}
                            scope="col"
                            className={classNames(
                              "px-6 py-3 text-left text-xs whitespace-nowrap font-medium text-gray-700 uppercase tracking-wider",
                              {
                                "text-center": Boolean(
                                  getColumnItemOptions(hi)?.alignCenter
                                ),
                                [getColumnItemOptions(hi)?.className!]: Boolean(
                                  getColumnItemOptions(hi)?.className
                                ),
                              }
                            )}
                          >
                            <div className="flex">
                              <span>{column.render("Header")}</span>
                              {Boolean(getColumnItemOptions(hi)?.useSorting) &&
                                (!column.isSortedDesc ? (
                                  <SortDescendingIcon
                                    className="h-5 w-5 cursor-pointer ml-3 text-gray-500"
                                    {...column.getSortByToggleProps()}
                                  />
                                ) : (
                                  <SortAscendingIcon
                                    className="h-5 w-5 cursor-pointer ml-3 text-gray-500"
                                    {...column.getSortByToggleProps()}
                                  />
                                ))}
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 && (
                  <tr
                    className="p-4 absolute left-1/2"
                    style={{ transform: "translateX(-50%)" }}
                  >
                    <td>
                      {notFoundText ??
                        "Sorry 😥, we couldn't find what you looking for."}
                    </td>
                  </tr>
                )}
                {
                  // Loop over the table rows
                  page.map((row, index) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr
                        {...row.getRowProps()}
                        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell, sIndex) => {
                            // Apply the cell props
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={classNames(
                                  "px-6 py-4 text-sm text-gray-500",
                                  {
                                    "font-medium text-gray-900":
                                      getColumnItemOptions(sIndex)?.emphasized,
                                    "text-center":
                                      getColumnItemOptions(sIndex)?.alignCenter,
                                    [getColumnItemOptions(sIndex)
                                      ?.className as string]:
                                      getColumnItemOptions(sIndex)?.className,
                                  }
                                )}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </ScrollableContainer>
      {pagination && (
        <nav
          className="flex justify-end items-center py-2 px-4 w-full bg-white border-t border-gray-300"
          ref={navRef}
        >
          <div className="flex space-x-1">
            <Button
              disabled={!canPreviousPage}
              onClick={() => {
                gotoPage(0);
                onPageChange?.(1);
              }}
              type="outlined"
            >
              <ChevronDoubleLeftIcon className="h-3 w-3" />
            </Button>

            <Button
              disabled={!canPreviousPage}
              onClick={() => {
                previousPage();
                onPageChange?.(state.pageIndex);
              }}
              type="outlined"
            >
              <ChevronLeftIcon className="h-3 w-3" />
            </Button>
          </div>
          <div className="px-3">
            <span>
              Page:{" "}
              <input
                type="number"
                value={state.pageIndex + 1}
                min={1}
                max={pagination.totalPages}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  if (pageNumber <= pagination.totalPages) {
                    gotoPage(pageNumber);
                    onPageChange?.(pageNumber + 1);
                  }
                }}
                className="px-2 py-1.5 border border-gray-300 placeholder-gray-500 rounded text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </span>
            <span> / {pagination.totalPages}</span>
          </div>
          <div className="flex space-x-1">
            <Button
              disabled={!canNextPage}
              onClick={() => {
                onPageChange?.(state.pageIndex + 2);

                nextPage();
              }}
              type="outlined"
            >
              <ChevronRightIcon className="h-3 w-3" />
            </Button>

            <Button
              disabled={!canNextPage}
              onClick={() => {
                onPageChange?.(pagination.totalPages);

                gotoPage(pagination.totalPages - 1);
              }}
              type="outlined"
            >
              <ChevronDoubleRightIcon className="h-3 w-3" />
            </Button>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Table;
