import React, { FunctionComponent } from "react";
import "./Spinner.css";
import classNames from "classnames";
interface OwnProps {
  size?: "small" | "large" | "default";
  color?: "primary" | "secondary";
}

type Props = OwnProps;

const Spinner: FunctionComponent<Props> = ({ size, color, children }) => {
  const sizeMap = {
    default: "h-8 w-8",
    small: "h-4 w-4",
    large: "h-12 w-12",
  };

  return (
    <div className={classNames("sk-circle", sizeMap[size!])}>
      {Array.from(Array(10).keys()).map((v) => (
        <div
          className={`sk-child sk-circle${
            v + 1
          } sk-child__size_${size} sk-child__color_${color}`}
          key={v}
        />
      ))}
    </div>
  );
};

Spinner.defaultProps = {
  size: "default",
  color: "primary",
};
export default Spinner;
